<template>
    <div class="block-cols" :class="isPopupPromocode ? 'blur' : ''">
        <div class="order-detailed">
            <div class="order-detailed__container _container">
                <aside class="sidebar">
                    <t-btn-arrow @click="$router.push('/products')" />
                    <h2 class="title">{{ title }}</h2>
                </aside>
                <div class="block-rows" v-if="items">
                    <div class="block-orders">
                        <div class="block-order" v-for="item in items" :key="item.id">
                            <div class="block-order__flex">
                                <div class="block-order__img">
                                    <img
                                        :src="item.image ? item.image : require(`@/assets/img/${nophoto}`)"
                                        :alt="item.name"
                                    />
                                </div>
                                <div class="block-order__content">
                                    <div class="block-col">
                                        <div class="block-order__left">
                                            <div class="block-order__title">
                                                <p v-if="item.type === 'discounted'">
                                                    ПРОМОКОД:
                                                    {{
                                                        item.name.length > 50
                                                            ? item.name.substr(0, 50) + "..."
                                                            : item.name
                                                    }}
                                                </p>
                                                <p v-else>
                                                    {{
                                                        item.name.length > 50
                                                            ? item.name.substr(0, 50) + "..."
                                                            : item.name
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="block-order__middle">
                                            <div class="block-order__weight">
                                                <p>{{ Math.round(item.weight) }} г</p>
                                            </div>
                                            <div class="block-order__price">
                                                <span v-if="item.price">{{ item.price * item.count }} ₽</span>
                                                <span v-else>0 ₽</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="block-order__right">
                                        <counter
                                            :item="item"
                                            @counter-cart="counterCart"
                                            @total-count-cart="totalCountCart"
                                        />
                                        <button class="btn btn-trash" @click="counterCart(item.id, 'delete')">
                                            {{ $t("buttons.remove") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <recomended title="Добавить к заказу?" :product_id="product_id" :isRecommended="isRecommended" />
                </div>
                <div v-else>
                    <br />
                    <h2 class="order-detailed__title">
                        <br />Корзина пуста!<br /><br />{{ $t("cart.cart_empty2") }}<br /><a
                            href="/products/"
                            class="btn btn-beige to-catalog"
                            >{{ $t("cart.to_catalog") }}.</a
                        >
                    </h2>
                </div>
            </div>
        </div>
        <div class="block-side">
            <div class="block-side__block">
                <div class="t-coin">
                    <template v-if="token">
                        <div class="t-coin__profile">
                            <h4 class="t-coin__title">
                                Привет,<br />
                                {{ userName }}
                            </h4>
                            <div class="bonuses">
                                <div class="bonuses__text">На твоем счете</div>
                                <div class="bonuses__flex">
                                    <div class="bonuses__values">
                                        {{ bonuses }}
                                    </div>
                                    <div class="bonuses__img">
                                        <img src="@/assets/img/svg/bonuses.svg" alt="bonuses" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="t-coin__body">
                            <h4 class="t-coin__title">Копи Т-коины</h4>
                            <p class="t-coin__subtite">Обменивай на блюда за 1₽</p>
                        </div>
                        <button class="t-coin__btn" @click="isPopupAuth = true">Копить Т-коины</button>
                    </template>
                    <div class="t-coin__img">
                        <img src="@/assets/img/coins2.png" alt="coins" />
                    </div>
                </div>
                <template v-if="items">
                    <input type="hidden" v-model="chopsticks" />
                    <div class="stroke">
                        <div class="stroke__title">Количество приборов</div>
                        <div class="stroke__counter">
                            <div class="stroke__int" @click="decreaseCounts">-</div>
                            <div class="stroke__val">{{ chopsticks }}</div>
                            <div class="stroke__int" @click="increaseCounts">+</div>
                        </div>
                    </div>
                    <div class="stroke" v-if="token">
                        <div class="stroke__body" v-if="promocode.active">
                            <div class="stroke__rows">
                                <div class="stroke__flex">
                                    <div class="stroke__text">Промокод</div>
                                    <div class="stroke__promocode">{{ promocode.code }}</div>
                                </div>
                                <div v-if="promocode.description" class="stroke__discount">
                                    {{ promocode.description }}
                                </div>
                            </div>
                            <button class="stroke__remove" @click="deletePromo()">Удалить</button>
                        </div>
                        <div class="stroke__body" v-else @click="applyPromo()">
                            <div class="stroke__title">Ввести промокод</div>
                            <div class="stroke__arrow">
                                <img src="@/assets/img/svg/arrow-input.svg" alt="arrow" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="block-side__block">
                <div class="total-sum">
                    <div class="total-sum__container">
                        <div class="total-sum__discount" v-if="promocode.active && promocode.type !== 'Product'">
                            <span>Скидка по промокоду</span>
                            <span v-if="promocode.type === 'Percent'"> {{ promocode.amount }} % </span>
                            <span v-else> {{ amount }} ₽</span>
                        </div>
                        <div class="total-sum__price">
                            {{ $t("footer_order.total") }}
                            <div class="total-sum__value">
                                <div class="removeOldPrice" v-if="promocode.active && promocode.type !== 'Product'">
                                    {{ price }}
                                    ₽
                                </div>
                                <div v-if="promocode.active && promocode.type !== 'Product'">
                                    <span v-if="promocode.type === 'Percent'"
                                        >{{ price - (price / 100) * Number(promocode.amount) }}₽</span
                                    >
                                </div>
                                <div v-else>
                                    {{ price }}
                                    ₽
                                </div>
                            </div>
                        </div>
                        <input type="hidden" v-model="delivery_type" />
                        <div class="block-form">
                            <div class="form_radio_btn" v-for="label in labels" :key="label.id">
                                <input
                                    :id="label.value"
                                    type="radio"
                                    name="radio"
                                    :value="label.value"
                                    :checked="label.checked"
                                    @click="delivery_type = label.value"
                                />
                                <label :for="label.value">{{ label.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="footer">
                    <div class="footer__container">
                        <div class="footer__buttons">
                            <button class="btn btn-green" @click="sendOrder">Оформить заказ</button>
                            <button class="btn btn-red" @click="cancelOrder">Отменить заказ</button>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
    <popup-promocode
        v-if="isPopupPromocode"
        @is-popup-keyboad="isPopupPromocode = false"
        @apply-promocode="applyPromocode"
        @reset-promocode="resetPromocode"
        :promocodeValidation="promocode.validation"
        :promocodeError="promocode.error"
    />
    <popup-auth v-if="isPopupAuth" @click="closeAuth" @close-auth="closeAuth" />
</template>

<script>
import axios from "axios";
//import { api } from "@/api/api";
import Recomended from "@/components/Recomended.vue";
import Counter from "@/components/counters/Counter.vue";
import PopupPromocode from "@/components/popups/PopupPromocode.vue";
import PopupAuth from "@/components/popups/PopupAuth.vue";

export default {
    components: { Recomended, Counter, PopupPromocode, PopupAuth },
    name: "t-order-detailed",
    data() {
        return {
            isPopupAuth: false,
            isPopupKeyboad: false,
            isPopupPromocode: false,
            title: "Корзина",
            userName: localStorage.getItem("name") ?? "",
            bonuses: 0,
            items: null,
            price: 0,
            count: 0,
            amount: "",
            promocode: {
                active: false,
                code: "",
                amount: null,
                description: null,
                validation: true,
                error: null,
                type: null,
            },
            token: localStorage.getItem("token"),
            nophoto: "nophoto.jpg",
            shop_id: 2,
            cafe_slug: "",
            labels: [
                { id: 1, name: "На подносе", value: "pickup_on_tray", checked: true },
                { id: 2, name: "С собой", value: "packed_pickup", checked: false },
            ],
            delivery_type: "pickup_on_tray",
            chopsticks: 0,
            now: new Date(),
            now2: new Date(),
        };
    },
    methods: {
        closeAuth() {
            this.isPopupAuth = false;
        },
        decreaseCounts() {
            this.chopsticks--;
            if (this.chopsticks < 1) this.chopsticks = 0;
        },
        increaseCounts() {
            this.chopsticks++;
        },
        applyPromo() {
            this.isPopupPromocode = true;
        },
        applyPromocode(promocodeInput) {
            this.promocode.code = promocodeInput;
            this.checkDiscount();
        },
        resetPromocode() {
            this.promocode.validation = true;
            this.promocode.error = "";
        },

        deletePromo() {
            this.promocode.active = false;
            this.promocode.code = null;
            if (this.promocode.type === "Product") {
                console.log(this.items);
                for (let i = 0; i < this.items.length; i++) {
                    console.log(this.items[i]);
                    if (this.items[i].type === "discounted") {
                        this.items.splice(i, 1);
                        localStorage.setItem("cart", JSON.stringify(this.items));
                    }
                }
            }
            localStorage.removeItem("promocode");
        },

        counterCart(id, type) {
            const array = this.items;

            const elem = array.find((item) => item.id === id);
            const index = array.findIndex((item) => item.id === id);

            if (type === "delete") {
                array.splice(index, 1);
                this.priceCart();
            } else if (type === "increase") {
                elem.count++;
                this.priceCart();
            } else {
                if (elem.count > 1) {
                    elem.count--;
                    this.priceCart();
                } else {
                    array.splice(index, 1);
                    this.priceCart();
                }
            }

            if (array.length === 0) {
                localStorage.removeItem("cart");
                this.items = null;
            } else {
                localStorage.setItem("cart", JSON.stringify(array));
            }
        },
        priceCart() {
            this.price = 0;
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].type !== "discounted") {
                    this.price += this.items[i].price * this.items[i].count;
                }
            }
        },
        totalCountCart() {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            return array.reduce((acc, item) => acc + item.count, 0);
        },
        sendOrder() {
            let headers = null;
            let items = [];
            let discount_code = this.promocode.code ?? null;

            if (this.token) {
                headers = {
                    Authorization: "Token " + this.token,
                };
            }

            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].type !== "discounted") {
                    let item = {};
                    item.quantity = this.items[i].count;
                    item.id = this.items[i].id;
                    items.push(item);
                }
            }

            if (items.length !== 0) {
                axios
                    .post(
                        `https://admin.tcode.online/api/projects/tubatay/orders/`,
                        {
                            shop_id: this.shop_id,
                            items: items,
                            //bonuses: this.bonuses,
                            delivery_type: this.delivery_type,
                            express: true,
                            discount_code: discount_code,
                            //order_date: this.order_date,
                            order_time_from:
                                String(this.now.getHours()).padStart(2, "0") +
                                ":" +
                                String(this.now.getMinutes()).padStart(2, "0"),
                            order_time_to:
                                String(this.now2.getHours()).padStart(2, "0") +
                                ":" +
                                String(this.now2.getMinutes()).padStart(2, "0"),
                            app_version: "Планшет",
                        },
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        if (response.data.success) {
                            localStorage.setItem("orderId", response.data.order.id);
                            this.$router.push("/payment");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        cancelOrder() {
            localStorage.clear();
            this.$router.push("/products");
        },

        checkDiscount() {
            //проверки скидки/промокода перед совершением заказа
            if ((this.items, this.promocode.code)) {
                let headers = null;

                let data = {
                    discount_code: this.promocode.code,
                    items: [],
                };

                if (this.token) {
                    headers = {
                        Authorization: "Token " + this.token,
                    };
                }

                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].type !== "discounted") {
                        let item = {
                            id: this.items[i].id,
                            quantity: this.items[i].count,
                        };

                        data.items.push(item);
                    }
                }

                axios
                    .post(
                        `https://admin.tcode.online/api/projects/tubatay/shops/${this.cafe_slug}/check-discount/`,
                        {
                            discount_code: data.discount_code,
                            items: data.items,
                        },
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        this.isPopupPromocode = false;
                        this.promocode.active = true;
                        this.applyDiscount(response.data);
                    })
                    .catch((error) => {
                        this.promocode.validation = false;
                        this.promocode.error = error.response.data.error;
                    });
            }
        },

        applyDiscount(data) {
            this.promocode.description = data.description;
            this.promocode.type = data.type;
            this.promocode.amount = data.amount;
            if (data.type === "Product") {
                const elem = this.items.find((item) => item.id === data.product.id);
                if (!elem) {
                    data.product.name = data.product.real_name;
                    data.product.type = "discounted";
                    data.product.this.items.push(data.product);
                    localStorage.setItem("cart", JSON.stringify(this.items));
                    this.priceCart();
                }
            }
            localStorage.setItem("promocode", JSON.stringify(this.promocode));
        },

        checkPromocode() {
            let promocode = JSON.parse(localStorage.getItem("promocode")) ?? null;

            if (promocode) {
                this.promocode = promocode;
            }
        },
    },
    mounted() {
        this.now.setTime(this.now.getTime() + 0 * 60 * 1000);
        this.now2.setTime(this.now.getTime() + 10 * 60 * 1000);
        console.log(
            String(this.now.getHours()).padStart(2, "0") + ":" + String(this.now.getMinutes()).padStart(2, "0")
        );
        console.log(
            String(this.now2.getHours()).padStart(2, "0") + ":" + String(this.now2.getMinutes()).padStart(2, "0")
        );

        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            cafe_slug = "univer";
        }
        if (this.cafe_slug === "localhost:8080") {
            this.cafe_slug = "it-park";
        }
        if (localStorage.getItem("cart")) {
            this.items = JSON.parse(localStorage.getItem("cart"));
            this.priceCart();
        }
        //точки продаж
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/`)
            .then((response) => {
                console.log(response.data);
                for (let i = 0; i < response.data.length; i++) {
                    if (this.cafe_slug === response.data[i].code) {
                        this.shop_id = response.data[i].id;
                        console.log(this.shop_id);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (this.token) {
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/profile/`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log("profile: " + response.data);
                    this.userName = response.data.first_name;
                    this.phone = response.data.username;
                    localStorage.setItem("name", response.data.first_name);
                    localStorage.setItem("phone", response.data.username);
                })
                .catch((error) => {
                    console.log(error);
                });
            //бонусы
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/bonuses`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.bonuses = response.data.bonuses;
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        this.checkPromocode();
        console.log(this.promocode);
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/keyboards";
@import "~@/assets/scss/auth";
.block-cols {
    display: flex;
    height: 100vh;
    .total-sum {
        flex: 1 1 auto;
        background: none;
    }
    .block-side {
        flex: 0 0 624px;
        padding: 25px 75px 20px 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .footer__buttons {
            flex-direction: column;
        }
        .footer__buttons .btn {
            min-height: 80px;
            margin-top: 15px;
            font-weight: 700;
            font-size: 22px;
            line-height: 30.01px;
            font-family: "Nunito Sans";
        }
        .footer__buttons .btn.btn-green {
            order: 0;
        }
        .t-coin {
            background: linear-gradient(135deg, rgba(72, 174, 147, 1) 25%, rgba(40, 115, 95, 1) 75%);
            margin-bottom: 30px;
            position: relative;
            width: 480px;
            height: 224px;
            border-radius: 20px;
            overflow: hidden;
            padding: 30px;
            color: #fff;
            font-family: "Nunito Sans";
            display: flex;
            flex-direction: column;
            &__body {
                flex: 1 1 auto;
            }
            &__title {
                font-weight: 700;
                font-size: 32px;
                line-height: 43.65px;
                flex: 1 1 auto;
            }

            &__subtite {
                font-size: 20px;
                line-height: 24px;
            }

            &__btn {
                width: 232px;
                height: 64px;
                border-radius: 15px;
                background: #fff;
                box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
                font-weight: 700;
                font-size: 20px;
                line-height: 27.28px;
                color: #317763;
                font-family: "Nunito Sans";
            }

            &__img {
                position: absolute;
                top: 0;
                right: 0;
                width: 200px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__profile {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
            }
        }

        .stroke {
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 2rem;
            margin-top: 15px;
            &__body {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;
                font-size: 22px;
                line-height: 30.01px;
                flex: 1 1 auto;
            }
            &__rows {
                display: flex;
                flex-direction: column;
            }
            &__flex {
                display: flex;
                align-items: center;
            }
            &__text {
                color: #000;
                padding-right: 20px;
            }
            &__promocode {
                color: #317763;
            }
            &__discount {
                font-size: 12px;
                line-height: 24px;
                color: #bebebe;
            }
            &__remove {
                width: 132px;
                height: 44px;
                border-radius: 20px;
                font-weight: 700;
                font-size: 22px;
                line-height: 30.01px;
                color: #bebebe;
            }
            &__title {
                font-weight: 700;
                font-size: 22px;
                line-height: 30.01px;
                color: #000;
            }
            &__counter {
                background-color: #e4f9f3;
                width: 132px;
                height: 43px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1rem;
                font-weight: 700;
                font-size: 22px;
                line-height: 30.01px;
                color: #317763;
            }

            &__int {
                cursor: pointer;
            }

            &__arrow {
                width: 15px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .bonuses {
            display: flex;
            flex-direction: column;
            &__text {
                font-weight: 700;
                font-size: 22px;
                line-height: 30.01px;
            }

            &__flex {
                display: flex;
                align-items: center;
            }

            &__values {
                font-weight: 700;
                font-size: 32px;
                line-height: 43.65px;
            }

            &__img {
                width: 27px;
                height: 25px;
                margin-left: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .footer {
        background: inherit;
    }
}
.block-rows {
    display: flex;
    flex-direction: column;
}
.block-order__title p {
    width: 100%;
}
.block-order__content {
    flex-direction: column;
    padding: 20px 30px 20px 20px;
}
.block-order__middle {
    justify-content: space-between;
}
.block-order__right {
    flex-direction: unset;
}
.block-order__right .counter,
.block-order .btn-trash {
    width: 50%;
}
.block-order__left,
.block-order__right {
    width: 100%;
}
.block-order .btn-trash {
    order: -1;
    margin-right: 15px;
}
.block-apply {
    display: flex;
    padding: 0 38px;
    margin: 0 -1rem;
    .apply-promo {
        width: 100%;
        height: 70px;
        margin: 2rem 0.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $ms3) {
            height: 50px;
        }
        @media (max-width: $ms4) {
            margin-top: 1rem;
        }
    }
}
.to-catalog {
    width: fit-content;
    margin: 5rem auto;
    background: #28735f;
    color: #fff;
    font-size: 40px;
    padding: 2rem;
    border-radius: 20px;
}
.block-orders {
    overflow-x: hidden;
    height: 60vh;
    &::-webkit-scrollbar-track {
        background: #28735f;
        border-radius: 100px;
        margin: 15px;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #76a597;
        border-radius: 100px;
        height: 183.6px;
    }

    @media (max-width: $ms3) {
        padding: 0 15px;
    }
    @media (max-width: $ms4) {
        padding: 0 10px;
    }
}

.order-detailed {
    background-color: #fff;
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
    @media (max-width: 1440px) {
        padding-bottom: 2rem;
    }
    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 15px;
    }

    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }
    .sidebar {
        align-items: center;
        height: 92px;
    }
    .title {
        flex: 1 1 auto;
        justify-content: center;
    }

    @media (max-width: $ms3) {
        padding-top: 50px;
    }
    &__promocode {
        @media (max-width: $ms3) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__title {
        text-align: center;
        color: #000;
        @include adaptive-font(50, 18, 0);
        @include adaptive-value("line-height", 51, 20, 0);
        padding-top: 200px;
        padding-bottom: 5rem;
    }

    &__promocode {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        @media (max-width: $ms3) {
            margin: 20px auto 0;
        }

        .btn {
            flex: 1 1 50%;
            height: 70px;
            display: flex;
            justify-content: center;
            margin: 45px 15px 0;
            @media (max-width: $ms3) {
                width: 100%;
                height: 50px;
                margin: 20px 15px 0;
            }
        }
    }

    &__container {
        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 43.65px;
            color: #000;
        }
    }
}
.total-sum {
    border-top: 1px solid #bebebe;
    &__value {
        display: flex;
        align-items: center;
    }
    &__price {
        padding-top: 10px;
        font-weight: 700;
        font-size: 22px;
        line-height: 30.01px;
    }
    &__discount {
        font-size: 20px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
    }
    .block-form {
        height: 80px;
        background-color: #f4f4f4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        margin-top: 30px;
        .form_radio_btn {
            display: inline-block;
            width: 50%;
        }
        .form_radio_btn input[type="radio"] {
            display: none;
        }
        .form_radio_btn label {
            width: 100%;
            height: 66px;
            cursor: pointer;
            line-height: 34px;
            border-radius: 15px;
            user-select: none;
            background: none;
            font-size: 20px;
            line-height: 27.28px;
            color: #717171;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        /* Checked */
        .form_radio_btn input[type="radio"]:checked + label {
            background: #fff;
            color: #317763;
            font-weight: 700;
            box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        }

        /* Hover */
        .form_radio_btn label:hover {
            background: #fff;
            color: #317763;
            font-weight: 700;
            box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        }

        /* Disabled */
        .form_radio_btn input[type="radio"]:disabled + label {
            background: #efefef;
            color: #666;
        }
    }
}
</style>

<template>
    <div class="payment">
        <div class="payment__container _container">
            <div class="payment__body">
                <div v-if="onProccess">
                    <h1 class="payment__title">Ожидаем оплаты...</h1>
                    <div class="payment__loads">
                        <img src="@/assets/img/svg/flower.svg" alt="loads" />
                    </div>
                    <h2 class="payment__subtitle">
                        <br />
                        {{ $t("payment.subtitle") }}
                        {{ $t("payment.subtitle1") }}<br />
                        {{ $t("payment.subtitle2") }}<br />{{ $t("payment.subtitle3") }}
                    </h2>
                    <div class="payment__arrow">
                        <img src="@/assets/img/svg/arrow-loads.svg" alt="arrow" />
                    </div>
                </div>
                <div class="onProccess" v-if="!onProccess && !paymentError">
                    <h2 class="payment__phrase">
                        Спасибо!<br />
                        Номер вашего заказа
                    </h2>
                    <h2 class="payment__subtitle">
                        <br />
                        <div v-if="!orderObj.order_number">
                            {{ $t("payment.subtitle_err") }}<br />
                            {{ $t("payment.subtitle_err1") }}<br />
                            {{ $t("payment.subtitle_err2") }}<br />
                            {{ $t("payment.subtitle_err3") }}<br />
                        </div>
                        <span class="order-number" v-if="orderObj.order_number"
                            ><font v-if="!orderObj.name"
                                >{{ $t("payment.name_kiosk") }}-{{ orderObj.order_number }}</font
                            ><font v-if="orderObj.name">{{ orderObj.name }}</font></span
                        >
                    </h2>
                    <div class="order-notifications" v-if="orderObj.order_number">
                        <p>
                            Сфотографируйте номер заказа<br />
                            и отслеживайте в зоне выдачи
                        </p>
                    </div>
                    <button class="btn-order">Заказать снова</button>
                    <div class="payment__apps">
                        <img src="@/assets/img/apps.png" alt="apps" />
                    </div>
                </div>
                <!--
        <div class="give-check" v-if="orderObj.order_number">
          <p>{{ $t("payment.give_check") }}</p>
          <img src="@/assets/img/svg/down.svg" alt="arrow" width="100" />
        </div>
        -->
                <div v-if="!onProccess && paymentError">
                    <h2 class="payment__subtitle">
                        <br />
                        {{ $t("payment.pay_err") }}<br />
                        {{ $t("payment.pay_err2") }}<br />
                        {{ $t("payment.pay_err3") }}<br />
                        <a @click="this.$router.go(this.$router.currentRoute)" class="btn btn-beige to-catalog">{{
                            $t("payment.repeat")
                        }}</a>
                        <a @click="this.$router.push('/')" class="btn btn-beige to-catalog">{{
                            $t("payment.cancel")
                        }}</a>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
//import { api } from "@/api/api";

export default {
    name: "t-payment",
    data() {
        return {
            onProccess: true,
            paymentError: true,
            orderObj: {},
            orderId: localStorage.getItem("orderId") ?? null,
            cafe_slug: "",
            items: null,
        };
    },
    mounted() {
        console.log(this.orderId);
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "univer";
        }
        let self = this;

        if (this.orderId) {
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/orders/` + this.orderId)
                .then(function (response) {
                    console.log(self.cafe_slug);
                    self.orderObj = response.data;
                    axios
                        .get("http://127.0.0.1:5000/payment/create/?amount=" + self.orderObj.full_price * 100)
                        .then((response) => {
                            self.onProccess = false;
                            console.log("TERMINAL: " + response.data);
                            if (response.data.success) {
                                console.log("TERMINAL: SUCCESS = " + response.data.success);
                                self.paymentError = false;
                                axios
                                    .post(
                                        `https://admin.tcode.online/api/projects/tubatay/shops/${self.cafe_slug}/order-confirm/`,
                                        {
                                            order_id: self.orderId,
                                            comment: "Заказ сделан через планшет",
                                        }
                                    )
                                    .then((response) => {
                                        self.onProccess = false;
                                        self.orderObj = response.data;

                                        axios.get(
                                            "http://127.0.0.1:5000/receipt/print/?text=" + self.orderObj.receipt_text
                                        );

                                        setTimeout(function () {
                                            location.href = "/";
                                            localStorage.clear();
                                        }, 12000);
                                    })
                                    .catch((err) => {
                                        console.log("API: ERROR = " + err);
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log("TERMINAL: ERROR = " + err);
                            self.paymentError = true;
                            self.onProccess = false;
                        });
                });
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.order-notifications {
    margin-top: 2rem;
    font-weight: 700;
    font-size: 22px;
    line-height: 30.01px;
    text-align: left;
}
.give-check {
    position: absolute;
    bottom: 0;
    right: 1rem;
    @include adaptive-font(55, 14, 0);
    @include adaptive-value("line-height", 50, 20, 0);
}
.order-number {
    font-weight: 700;
    font-size: 200px;
    line-height: 350px;
}
.order-info {
    @include adaptive-font(55, 14, 0);
    @include adaptive-value("line-height", 50, 20, 0);
    width: 50%;
    display: block;
    margin: 1rem auto 0;
}
.payment {
    background-color: $green;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    height: 79vh;
    position: relative;
    font-family: "Nunito Sans";
    .btn-order {
        width: 480px;
        height: 80px;
        border-radius: 20px;
        box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        font-weight: 700;
        font-size: 32px;
        line-height: 43.65px;
        color: #28735f;
        margin-top: 120px;
    }

    &__body {
        color: #fff;
        text-align: center;
        @include adaptive-font(70, 20, 0);
        @include adaptive-value("line-height", 85, 20, 0);
    }

    &__title {
        font-weight: 700;
        font-size: 48px;
        line-height: 65.47px;
    }

    &__subtitle {
        font-weight: 700;
        font-size: 32px;
        line-height: 43.65px;
    }

    &__loads {
        width: 187px;
        height: 164px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 200px auto 150px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 352px;
            height: 352px;
            border-top: 1px solid #fff;
            border-left: 3px solid #fff;
            border-radius: 50%;
            transform: rotate(0);
            animation: rotateMove 1s infinite 1s;
            @keyframes rotateMove {
                0% {
                    transform: rotate(0);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
        @media (max-width: $ms3) {
            width: 50px;
            height: 50px;
            margin: 50px auto 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__arrow {
        width: 30.32px;
        height: 13.47px;
        margin: 2rem auto 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__phrase {
        font-weight: 700;
        font-size: 48px;
        line-height: 65.47px;
        text-align: left;
    }
    &__apps {
        width: 570px;
        height: 775px;
        position: absolute;
        bottom: 0;
        right: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .onProccess {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100vw;
        padding: 0 150px;
    }
}
</style>

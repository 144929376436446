<template>
  <div class="keyboard">
    <div class="keyboard__input _container">
      <form class="search-input" @click.stop>
        <div class="search-input__block">
          <div class="btn-find btn-circle">
            <img src="@/assets/img/svg/find.svg" alt="find" width="30.9" />
          </div>
          <input
            type="text"
            name="q"
            placeholder="Поиск"
            class="search-input__input"
            v-model="searchValue"
            autocomplete="off"
          />
          <div
            class="search-input__remove"
            v-if="searchValue"
            @click="clearInput"
          >
            <img :src="require(`@/assets/img/svg/remove.svg`)" alt="remove" />
          </div>
        </div>
      </form>
      <button class="btn-circle" @click="$emit('isPopupKeyboad')">
        <img src="@/assets/img/svg/exit.svg" alt="exit" />
      </button>
    </div>
    <div class="keyboard__content">
      <div
        class="keyboard__nothing"
        v-if="!products.length && searchValue.length >= 3"
      >
        <div class="keyboard__loop">
          <img src="@/assets/img/loop.png" alt="loop" />
        </div>
        <div class="keyboard__message">
          <p>Такого у нас нет!</p>
          <p>Попробуйте изменить запрос</p>
        </div>
      </div>
      <div class="keyboard__result _container" v-else>
        <div class="blocks" v-if="searchValue" @click.stop>
          <div
            class="block"
            v-for="product in products"
            :key="product.id"
            :class="product.is_popular ? `popular` : ``"
          >
            <div class="block__img" @click="showProductId(product.id)">
              <div class="tagProduct hit" v-if="product.is_popular">Хит</div>
              <img :src="product.image" :alt="product.real_name" />
              <div class="count">
                {{ product.price }}
              </div>
            </div>
            <div class="block__body">
              <div class="block__title" @click="showProductId(product.id)">
                {{ product.real_name }}
              </div>
              <div>
                <counter-price
                  :product="product"
                  @total-count-cart="totalCountCart"
                />
                <!-- <div @click="addToCart(product)" class="btn-more">
                      <span>Нет в наличии</span>
                    </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="keyboard__tag">Здесь нет того, что я хочу</div>
    </div>
    <div class="keyboard__block" @click.stop>
      <div class="keyboard__container _container">
        <div class="keyboard__body">
          <div class="keyboard__keys" @click="typeLetter">
            <div class="keyboard__row">
              <button
                class="keyboard__key key"
                v-for="(number, index) in 9"
                :key="index"
              >
                {{ number }}
              </button>
              <button class="keyboard__key key">0</button>
              <button class="btn btn-lightgreen btn-long key">
                {{ $t("cart.btn_clear") }}
              </button>
            </div>
            <div class="keyboard__row">
              <button
                class="keyboard__key key"
                v-for="letter in lettersLang.row1"
                :key="letter.id"
              >
                {{ letter.char }}
              </button>
              <button class="btn btn-lightgreen btn-long2 key">
                <img
                  src="@/assets/img/svg/clear.svg"
                  alt="clear"
                  class="clear"
                />
              </button>
            </div>
            <div class="keyboard__row">
              <button
                class="keyboard__key key"
                v-for="letter in lettersLang.row2"
                :key="letter.id"
              >
                {{ letter.char }}
              </button>
              <button class="btn btn-lightgreen btn-long2 key">
                <img
                  src="@/assets/img/svg/space.svg"
                  alt="space"
                  class="space"
                />
              </button>
            </div>
            <div class="keyboard__row">
              <button
                class="keyboard__key key"
                v-for="letter in lettersLang.row3"
                :key="letter.id"
              >
                {{ letter.char }}
              </button>
              <button
                class="btn btn-lightgreen btn-long key"
                @click="changeLang"
              >
                {{ lang }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";
import CounterPrice from "@/components/counters/CounterPrice.vue";
export default {
  components: { CounterPrice },
  name: "t-popup-keyboard",
  data() {
    return {
      isShowProduct: false,
      categories: [],
      selectedCategoryId: 0,
      products: [],
      searchValue: "",
      lettersRu: {
        row1: [
          { id: 1, char: `й` },
          { id: 2, char: `ц` },
          { id: 3, char: `у` },
          { id: 4, char: `к` },
          { id: 5, char: `е` },
          { id: 6, char: `н` },
          { id: 7, char: `г` },
          { id: 8, char: `ш` },
          { id: 9, char: `щ` },
          { id: 10, char: `з` },
          { id: 11, char: `х` },
          { id: 12, char: `ъ` },
        ],
        row2: [
          { id: 1, char: `ф` },
          { id: 2, char: `ы` },
          { id: 3, char: `в` },
          { id: 4, char: `а` },
          { id: 5, char: `п` },
          { id: 6, char: `р` },
          { id: 7, char: `о` },
          { id: 8, char: `л` },
          { id: 9, char: `д` },
          { id: 10, char: `ж` },
          { id: 11, char: `э` },
        ],
        row3: [
          { id: 1, char: `я` },
          { id: 2, char: `ч` },
          { id: 3, char: `с` },
          { id: 4, char: `м` },
          { id: 5, char: `и` },
          { id: 6, char: `т` },
          { id: 7, char: `ь` },
          { id: 8, char: `б` },
          { id: 9, char: `ю` },
          { id: 10, char: `_` },
        ],
      },
      lettersEng: {
        row1: [
          { id: 1, char: `a` },
          { id: 2, char: `b` },
          { id: 3, char: `c` },
          { id: 4, char: `d` },
          { id: 5, char: `e` },
          { id: 6, char: `f` },
          { id: 7, char: `g` },
          { id: 8, char: `h` },
          { id: 9, char: `i` },
          { id: 10, char: `j` },
          { id: 11, char: `k` },
          { id: 12, char: `l` },
        ],
        row2: [
          { id: 1, char: `m` },
          { id: 2, char: `n` },
          { id: 3, char: `o` },
          { id: 4, char: `p` },
          { id: 5, char: `q` },
          { id: 6, char: `r` },
          { id: 7, char: `s` },
          { id: 8, char: `t` },
          { id: 9, char: `u` },
          { id: 10, char: `v` },
          { id: 11, char: `w` },
        ],
        row3: [
          { id: 1, char: `x` },
          { id: 2, char: `y` },
          { id: 3, char: `z` },
        ],
      },
      lang: "ENG",
    };
  },
  methods: {
    changeLang() {
      this.lang === "ENG" ? (this.lang = "РУС") : (this.lang = "ENG");
    },
    typeLetter() {
      if (event.target.className !== "keyboard__row") {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear")
          this.searchValue = this.searchValue.substr(
            0,
            this.searchValue.length - 1
          );
        else if (key === "Очистить") this.searchValue = "";
        else if (key === "space") this.searchValue += " ";
        else if (key === "ENG" || key === "РУС") this.changeLang;
        else this.searchValue += key.toUpperCase();
      }
    },
    showProductId(product_id) {
      console.log(product_id);
      this.product_id = product_id;
      this.isShowProduct = true;
    },
    clearInput() {
      var self = this;
      self.searchValue = "";
      axios
        .get(api + `categories/`)
        .then((response) => {
          self.categories = response.data.results;
          self.selectedCategoryId = self.categories[0].id;
          axios
            .get(api + `products/?category=` + self.selectedCategoryId)
            .then((response) => {
              self.products = response.data.results;
              console.log(" this.products", self.products);
            })
            .catch((e) => {
              //self.errors.cafes = e.message;
              self.errors = e.message;
            });
        })
        .catch((e) => {
          //self.errors.cafes = e.message;
          self.errors = e.message;
        });
    },
    totalCountCart() {
      const array = JSON.parse(localStorage.getItem("cart")) ?? [];
      return array.reduce((acc, item) => acc + item.count, 0);
    },
  },
  watch: {
    searchValue: function (newVal, oldVal) {
      // watch it
      if (newVal.length >= 3) {
        this.selectedCategoryId = 0;
        axios
          .get(api + `products/?search=` + this.searchValue)
          .then((response) => {
            this.products = response.data.results;
            console.log(" this.products", self.products);
          })
          .catch((e) => {
            this.errors.cafes = e.message;
            this.errors = e.message;
          });
      }
      console.log(`Search was ${oldVal} now - ${newVal}`);
    },
  },
  mounted() {
    //Товары
    axios
      .get(api + `products`)
      .then((response) => {
        console.log(response.data);
        this.products = response.data.results;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    lettersLang() {
      return this.lang === "ENG" ? this.lettersRu : this.lettersEng;
    },
  },
};
</script>
